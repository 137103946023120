<template>
  <div class="img-upload-box">
    <!-- file uploader input -->
    <input
      type="file"
      class="d-none"
      :id="`file-uploader-${data.id}`"
      :name="`file-uploader-${data.id}`"
      @change="addFile"
    />
    <!-- title  -->
    <h6 class="img-upload-box-label">{{ data.title }}</h6>
    <!-- box  -->
    <label class="m-0 w-100" :for="`file-uploader-${data.id}`">
      <div
        class="img-box d-flex align-items-center justify-content-center flex-column"
        :style="{
          backgroundImage: currentDoc ? `url(${currentDoc.url})` : null,
          backgroundSize: 'cover',
        }"
      >
        <div class="img-inner" v-if="!currentDoc && !loading">
          <i class="las la-image icon"></i>
        </div>
        <div class="img-inner" v-if="loading">
          <b-spinner label="loading..."></b-spinner>
        </div>
        <span class="types" v-if="!currentDoc && !loading"
          >( {{ data.type }} )</span
        >
      </div>
      <div class="img-upload-box-btn mt-1 text-center py-1">اختر الصورة</div>
    </label>
  </div>
</template>

<script>
/*eslint-disable */
import doctorApi from "../services/doctors";

export default {
  props: ["data", "index"],
  data() {
    return {
      currentDoc: null,
      loading: false,
      previousFile: null,
    };
  },
  methods: {
    addFile(e) {
      const targetFile = e.target.files[0];

      if (
        !targetFile ||
        (this.previousFile && this.previousFile.name === targetFile.name)
      ) {
        e.target.value = "";
        return;
      }

      this.previousFile = targetFile;

      this.loading = true;
      const fileFormData = new FormData();
      fileFormData.append("file", targetFile);
      fileFormData.append("app_name", "veterinary");
      fileFormData.append("directory", "doctors");

      doctorApi
        .uploadDotorDocument(fileFormData)
        .then((res) => {
          this.currentDoc = { url: res.data.url, doc_id: this.data.id };
          this.$emit("uploadDocument", {
            url: res.data.url,
            doc_id: this.data.id,
          });

          e.target.value = "";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
